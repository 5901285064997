<form [formGroup]="datectlForm">
  <div class="form-group">
    <label for="datectlv2">
      <label class="text-primary">{{labelDateCtl}}</label>
      <span class="text-danger ml-1">*</span>
    </label>
    <div class="input-group">
      <input class="form-control" placeholder="yyyy-mm-dd"
        formControlName="datectlv2"  [id]="DateCtlId" [name]="DateCtlId" (dateSelect)="onDateCtlV2DateSelect($event)" (change)="onDateCtlV2DateChange($event)" ngbDatepicker #d24="ngbDatepicker">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="d24.toggle()" type="button"></button>
      </div>
    </div>
  </div>
</form>
