<div class="row mb-3">
  <div class="col-5">
    <span placement="bottom" ngbTooltip={{meetingAction.description}}>
      &#10069;
    </span>
    <span>{{meetingAction.id}} - </span>
    <span>{{meetingAction.name}}</span>
  </div>
  <div class="col-2">{{meetingAction.completionDate | date:'dd-MMM-yy'}}</div>
  <div class="col-2">{{meetingAction.forename}}</div>

  <div class="col-2" *ngIf="meetingAction.priority==='High'">
    <span style="color: red;">{{meetingAction.priority}}</span>
  </div>
  <div class="col-2" *ngIf="meetingAction.priority==='Medium'">
    <span style="color:goldenrod">{{meetingAction.priority}}</span>
  </div>
  <div class="col-2" *ngIf="meetingAction.priority==='Low'">
    <span>{{meetingAction.priority}}</span>
  </div>
  <div class="col-1">
    <button id="show-complete-dialog" class="btn btn-primary btn-sm" (click)="showActionCompleteDialog(meetingAction.id)">Complete</button>
  </div>
</div>
<hr />

