<div class="container mt-4">

  <h1 class="display-4">Spend Categories</h1>
  <div *ngIf="loading" class="spinner-border"></div>

  <div class="container">
    <div class="row mb-3">
      <div class="col-md text-right">
        <button id="add-category" class="btn btn-primary btn-sm" (click)="openModal(contentAdd, 0)">Add</button>
      </div>
    </div>
  </div>

  <div class="row mb-3" *ngIf="spendCategories.length === 0">
    <div class="col-5">
      Categories not found.
    </div>
  </div>

  <!-- header -->
  <div class="row bg-info text-white mb-3" *ngIf="spendCategories.length > 0">
    <div class="col-5">
      Category Name
    </div>
    <div class="col-5">
      Authorized To
    </div>
    <div class="col-2">
      &nbsp;
    </div>
  </div>
  <!-- end of header-->

  <div *ngFor="let category of spendCategories">
    <div class="row mt-1">
      <div class="col-5" >
          {{category.name}}
          <div>
            {{category.catCode}}
          </div>
      </div>
      <div class="col-5" >
        <span *ngFor="let rl of category.roles; index as i; last as isLast">
          {{rl.name}} <span *ngIf="!isLast">,</span>
        </span>
      </div>
      <div class="col-2 text-right">
        <button type="button" class="btn btn-outline-dark btn-sm mr-1"
        [attr.data-cy-category-edit]="category.id"
        (click)="openModal(contentAdd, category.id)" style='font-size:14px;'>&#9998;</button>
      </div>
      <div class="h-divider"></div>
    </div>
  </div>






  <!-- set dialog modal -->
  <ng-template #contentAdd let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{categoryDialogTitle}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="createCategoryForm">
        <div class="form-group">
          <label class="text-primary" for="spendMasterCategory">Master Category</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <select id="masterCategory" formControlName="masterCategory" class="form-control" (change)="onMasterCategoryChange($event)">
              <option  *ngFor="let mc of spendMasterCategories; let index" [value]=mc.id>
                {{mc.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label class="text-primary" for="name">Name</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="name"  id="name" name="name" (change)="onNameChange($event)" placeholder="" [value]="">
          </div>
        </div>

        <div class="form-group">
          <label class="text-primary" for="name">Category Code</label>
          <span class="text-danger ml-1">*</span>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="catCode"  id="catCode" name="catCode" (change)="onCatCodeChange($event)" placeholder="ABCD" [value]="">
          </div>
        </div>

        <div>
          <label class="text-primary" for="name">Assign Role</label>
          <div *ngFor="let role of newRoles">
            <input [attr.data-cy-role]="role.id" [checked]="role.isChecked === true" type="checkbox" name="role" id="role"
            (click)="onRoleChange($event, role.id)" [value]="role.id">
            <label class="ml-3">{{role.name}}</label>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <div *ngIf="error" style="color: red">{{error}}</div>
      <button type="button" id="save-category" [disabled]="saving" class="btn btn-primary" (click)="saveCategory()">
        <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
        Save
      </button>
    </div>
  </ng-template>
  <!-- endof dialog modal -->

</div>
