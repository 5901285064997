<div class="container mt-4">

  <h1 class="display-4">Meeting Category/Actions</h1>
  <div *ngIf="loading" class="spinner-border"></div>

  <div class="container">
    <div class="row mb-3">
      <div class="col-md text-right">
        <button id="add-meeting-category-action-items" class="btn btn-primary btn-sm" (click)="navToMeetingCategoryActionItemsEdit()" >Add Category</button>
      </div>
    </div>
  </div>

  <div class="row mb-3" *ngIf="meetingCategories.length === 0">
    <div class="col-5">
      Categories not found.
    </div>
  </div>

  <div class="row" *ngFor="let cat of meetingCategories">
    <div class="col-12">
      <div class="row">
        <div class="col-11">
          <h5 class="text-primary mb-3">{{cat.name}}</h5>
        </div>
        <div class="col-1">
          <span class="text-center ml-3">
            <button type="button" class="btn btn-primary btn-md" (click)="openModal(contentAdd, -1, cat.id)" style='font-size:14px;'>+</button>
          </span>
        </div>
      </div>

      <div class="row" *ngFor="let act of cat.meetingActionItems">
        <div class="col-12 ml-3 mb-2">
          <div class="row">
            <div class="col-5">{{act.name}}</div>
            <div class="col-4">{{act.description}}</div>
            <div class="col-2">
              <span *ngIf="act.isAdhoc === true">Adhoc</span>
            </div>
            <div class="col-1">
              <button type="button" class="btn btn-outline-dark btn-sm text-center" (click)="openModal(contentAdd, act.id, cat.id)" style='font-size:14px;'>&#9998;</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- set dialog modal -->
  <ng-template #contentAdd let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Action Item</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <meeting-action-edit-popup-tmpl
      [selectedOrNewActionItem]="selectedActionItem"
      [saving]="saving"
      (selectedOrNewActionItemUpdatedEvent)="updatedSelectedOrNewActionItem($event)"></meeting-action-edit-popup-tmpl>

  </ng-template>
  <!-- end of dialog-->

  <div class="mt-5"></div>

</div>
