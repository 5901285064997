<div class="container mt-4">

  <h1 class="display-4">Edit Meetings</h1>

  <div class="container"> <!--1st container title, date -->
    <div class="row">
      <div class="col-8">
        <h5 class="text-primary">{{newMeeting.title}}</h5>
      </div>
      <div class="col-4">
        <h6 class="text-primary">{{newMeeting.meetingDate | date:'dd-MMM-yy'}}</h6>
      </div>
    </div>
  </div> <!--1st container title, date -->

  <div class="mt-1">&nbsp;</div>

  <meeting-action-edit-tmpl
    [meetingActionRequests]="newMeeting.meetingActions"
    [actionOwners]="actionOwners"
    [showFrequencyCtls]=false
    [showActionSelectCheckboxCtl]=false
    [showSaveButtonCtl]=true
    (updatedActionEvent)="updatedAction($event)">
  </meeting-action-edit-tmpl>

  <div *ngIf="errors.length > 0">
    <div *ngFor="let error of errors" style="color: red">
      {{ error }}
    </div>
  </div>

  <div class="mb-5">&nbsp;</div>

</div>